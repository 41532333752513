import { useCallback, useEffect, useMemo, useState } from 'react'

import { DEFAULT_PAGE_SIZE } from 'constant'
import { useInitialParams } from 'features/common/hooks/useInitialParams'
import {
  MapSearchParams,
  InventoryGroup,
  InventoryGroupSortType,
  FilterCategory,
  MetaSearchResponse,
} from 'features/common/types'
import { useGetInventoryGroupsQuery } from 'store/booking/api'
import { selectBooking } from 'store/booking/slice'
import { useAppSelector } from 'store/store'

export interface IUseInventoryGroupSearch {
  initialLoading: boolean
  isLoading: boolean
  hasNextPage: boolean
  inventoryGroups: InventoryGroup[]
  loadMore: () => void
  total: number
  setPageSize: (size: number) => void
}

interface UseInventoryGroupSearchProps {
  metaSearchData: MetaSearchResponse | undefined
  currency: string
  sort: InventoryGroupSortType
  filterOption?: FilterCategory | undefined
  mapBounds?: MapSearchParams
  vendorId?: string | undefined
}

export const useInventoryGroupSearch = ({
  metaSearchData,
  currency,
  sort,
  filterOption,
  mapBounds,
  vendorId,
}: UseInventoryGroupSearchProps): IUseInventoryGroupSearch => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)
  const [updatedFilterOption, setUpdatedFilterOption] = useState<FilterCategory | undefined>(
    filterOption,
  )

  const { inventoryGroups } = useAppSelector(selectBooking)

  const {
    params: { locationId, lat, lng, radius, polygons, fromView },
  } = useInitialParams()

  const activityIds = useMemo(
    () => metaSearchData?.activities?.map(a => a._id),
    [metaSearchData?.activities],
  )

  const params = useMemo(() => {
    if (vendorId) {
      return {
        vendorId,
        currency,
        types: updatedFilterOption?.types.filter(t => t.selected).map(t => t._id),
        durations: updatedFilterOption?.durations
          .filter(d => d.selected)
          .map(d => d.value.value_in_mins),
        min_price: updatedFilterOption?.min_price?.selected,
        max_price: updatedFilterOption?.max_price?.selected,
        min_review_rating: updatedFilterOption?.min_review_rating,
        page,
        pageSize,
        sort_by: sort,
        from_view: fromView,
      }
    }
    return {
      locationId: metaSearchData?.location?._id,
      pin_lat: lat,
      pin_lng: lng,
      radius,
      polygons: polygons && JSON.stringify(polygons),
      from_view: fromView,
      activityIds,
      currency,
      types: updatedFilterOption?.types.filter(t => t.selected).map(t => t._id),
      durations: updatedFilterOption?.durations
        .filter(d => d.selected)
        .map(d => d.value.value_in_mins),
      min_price: updatedFilterOption?.min_price?.selected,
      max_price: updatedFilterOption?.max_price?.selected,
      min_review_rating: updatedFilterOption?.min_review_rating,
      page,
      pageSize,
      sort_by: sort,
      neLat: mapBounds?.neLat,
      neLng: mapBounds?.neLng,
      swLat: mapBounds?.swLat,
      swLng: mapBounds?.swLng,
    }
  }, [
    activityIds,
    currency,
    lat,
    lng,
    polygons,
    mapBounds?.neLat,
    mapBounds?.neLng,
    mapBounds?.swLat,
    mapBounds?.swLng,
    metaSearchData?.location?._id,
    page,
    pageSize,
    radius,
    sort,
    updatedFilterOption?.durations,
    updatedFilterOption?.max_price?.selected,
    updatedFilterOption?.min_price?.selected,
    updatedFilterOption?.min_review_rating,
    updatedFilterOption?.types,
    vendorId,
    fromView,
  ])

  const { data, isFetching, isLoading } = useGetInventoryGroupsQuery(params, {
    skip:
      (!metaSearchData?.location?._id && !(lat && lng) && !vendorId && !polygons) ||
      (activityIds?.length === 0 && !vendorId),
    refetchOnMountOrArgChange: true,
  })

  useEffect(() => {
    setUpdatedFilterOption(prev => {
      if (prev) {
        return {
          ...prev,
          min_price: undefined,
          max_price: undefined,
        }
      }
      return undefined
    })
  }, [currency])

  const hasNextPage = useMemo(() => {
    if (!data) return false
    if (data.page_size !== data.items.length) return false
    return inventoryGroups.length < data.total_items
  }, [data, inventoryGroups.length])

  const loadMore = useCallback(() => {
    if (hasNextPage) {
      setPage(page + 1)
    }
  }, [hasNextPage, page])

  useEffect(() => {
    setUpdatedFilterOption(filterOption)
  }, [filterOption])

  useEffect(() => {
    setPage(1)
  }, [
    metaSearchData,
    locationId,
    lat,
    lng,
    radius,
    activityIds,
    currency,
    pageSize,
    sort,
    filterOption,
    mapBounds,
    vendorId,
  ])

  return {
    initialLoading: isLoading,
    isLoading: isFetching,
    hasNextPage,
    inventoryGroups,
    loadMore,
    total: data?.total_items || 0,
    setPageSize,
  }
}
