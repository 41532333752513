import { useInitialParams } from 'features/common/hooks/useInitialParams'
import { MetaSearchResponse } from 'features/common/types'
import { useGetMetaSearchQuery } from 'store/booking/api'

export interface IUseMetaSearch {
  data: MetaSearchResponse | undefined
  isLoading: boolean
}

export const useMetaSearch = (): IUseMetaSearch => {
  const {
    params: { locationId, locationName, lat, lng, radius, polygons, activities, activityIds },
    skipCall: skip,
  } = useInitialParams()
  const { data, isLoading } = useGetMetaSearchQuery(
    {
      locationId,
      location: locationName,
      pin_lat: lat,
      pin_lng: lng,
      radius,
      polygons: polygons && JSON.stringify(polygons),
      activities: activities?.length === 0 ? undefined : activities,
      activityIds: activityIds?.length === 0 ? undefined : activityIds,
    },
    { skip, refetchOnMountOrArgChange: true },
  )

  return {
    data,
    isLoading,
  }
}
