import React, { createContext, useContext, useEffect, useState } from 'react'

import { IUseCurrency, useCurrency } from 'features/common/hooks/useCurrency'
import {
  IUseInventoryGroupSearch,
  useInventoryGroupSearch,
} from 'features/common/hooks/useInventoryGroupSearch'
import { useMetaSearch } from 'features/common/hooks/useMetaSearch'
import { IUseMyLocation, useMyLocation } from 'features/common/hooks/useMylocation'
import {
  FilterCategory,
  InventoryGroupSortType,
  MapSearchParams,
  MetaSearchResponse,
} from 'features/common/types'

export interface AppProviderProps {
  children: React.ReactNode
}

export interface IContextProps
  extends IUseCurrency,
    IUseInventoryGroupSearch,
    IUseMyLocation<boolean> {
  sort: InventoryGroupSortType
  setSort: (option: InventoryGroupSortType) => void
  discount: boolean
  setDiscount: (discount: boolean) => void
  filterOption: FilterCategory | undefined
  setFilterOption: (option: FilterCategory | undefined) => void
  filteredCount: number
  setFilteredCount: (count: number) => void
  isMetaSearchLoading: boolean
  metaSearchData: MetaSearchResponse | undefined
  mapBounds: MapSearchParams | undefined
  setMapBounds: (bounds: MapSearchParams | undefined) => void
  vendorId: string | undefined
  setVendorId: (id: string | undefined) => void
}

export const SearchContext = createContext<IContextProps>({
  currency: undefined,
  currencies: [],
  setCurrency: () => undefined,

  isLoading: false,
  initialLoading: false,
  inventoryGroups: [],
  loadMore: () => undefined,
  hasNextPage: false,
  total: 0,
  setPageSize: () => undefined,

  sort: 'best_rating',
  setSort: () => undefined,
  discount: false,
  setDiscount: () => undefined,
  filterOption: undefined,
  setFilterOption: () => undefined,
  filteredCount: 0,
  setFilteredCount: () => undefined,

  isMetaSearchLoading: false,
  metaSearchData: undefined,

  myLocation: undefined,
  requestLocation: () => undefined,
  checkLocationPermission: () => undefined,
  showPermission: false,
  showModal: () =>
    new Promise(resolve => {
      resolve(false)
    }),
  closeModal: () => undefined,
  confirm: () => undefined,
  permissionStatus: {
    isPermissionDenied: false,
    permissionDeniedReason: null,
  },

  mapBounds: undefined,
  setMapBounds: () => undefined,

  vendorId: undefined,
  setVendorId: () => undefined,
})

export const useSearch = (): IContextProps => useContext(SearchContext)

const SearchProvider = ({ children }: AppProviderProps) => {
  const [sort, setSort] = useState<InventoryGroupSortType>('best_rating')
  const [discount, setDiscount] = useState<boolean>(false)
  const [filterOption, setFilterOption] = useState<FilterCategory | undefined>(undefined)
  const [filteredCount, setFilteredCount] = useState<number>(0)
  const [mapBounds, setMapBounds] = useState<MapSearchParams | undefined>(undefined)
  const [vendorId, setVendorId] = useState<string | undefined>(undefined)

  const {
    myLocation,
    requestLocation,
    showPermission,
    showModal,
    closeModal,
    confirm,
    permissionStatus,
    checkLocationPermission,
  } = useMyLocation()

  const { currency, setCurrency, currencies } = useCurrency()

  const { data: metaSearchData, isLoading: isMetaSearchLoading } = useMetaSearch()

  const { inventoryGroups, loadMore, isLoading, hasNextPage, initialLoading, total, setPageSize } =
    useInventoryGroupSearch({
      metaSearchData,
      currency: currency?.code || 'USD',
      filterOption,
      sort,
      mapBounds,
      vendorId,
    })

  useEffect(() => {
    checkLocationPermission()
  }, [checkLocationPermission])

  useEffect(() => {
    setFilterOption(undefined)
    setMapBounds(undefined)
    setFilteredCount(0)
  }, [vendorId])

  return (
    <SearchContext.Provider
      value={{
        currency,
        currencies,
        setCurrency,

        isLoading,
        initialLoading,
        hasNextPage,
        inventoryGroups,
        loadMore,
        total,
        setPageSize,

        sort,
        setSort,
        discount,
        setDiscount,
        filterOption,
        setFilterOption,
        filteredCount,
        setFilteredCount,

        isMetaSearchLoading,
        metaSearchData,

        myLocation,
        requestLocation,
        showPermission,
        showModal,
        closeModal,
        confirm,
        permissionStatus,
        checkLocationPermission,

        mapBounds,
        setMapBounds,

        vendorId,
        setVendorId,
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}

export default SearchProvider
