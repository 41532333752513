import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { InitialParams } from 'features/common/types'

import { validatePolygonString } from '../helpers/validatePolygonString'

export interface IUseInitialParams {
  params: InitialParams
  skipCall: boolean
  updateSearchParam: (params: { key: string; value: string | number | undefined }[]) => void
}

export const useInitialParams = (): IUseInitialParams => {
  const [searchParams, setSearchParams] = useSearchParams()

  const activityIds = useMemo(
    () => searchParams.get('activityIds')?.split(',') || [],
    [searchParams],
  )
  const locationId = useMemo(() => searchParams.get('locationId') || undefined, [searchParams])
  const location = useMemo(() => searchParams.get('location') || undefined, [searchParams])
  const activities = useMemo(() => searchParams.get('activities')?.split(',') || [], [searchParams])
  const lat = useMemo(() => searchParams.get('pin_lat') || undefined, [searchParams])
  const lng = useMemo(() => searchParams.get('pin_lng') || undefined, [searchParams])
  const radius = useMemo(() => searchParams.get('radius') || undefined, [searchParams])
  const polygons = useMemo(
    () => validatePolygonString(searchParams.get('polygons') || undefined),
    [searchParams],
  )
  const fromView = useMemo(() => searchParams.get('from_view') || undefined, [searchParams])

  const skipCall = useMemo(
    () => !locationId && !location && !(lat && lng) && !polygons,
    [lat, lng, location, locationId, polygons],
  )

  const updateSearchParam = (params: { key: string; value: string | number | undefined }[]) => {
    const newSearchParams = new URLSearchParams()
    params.forEach(({ key, value }) => {
      if (value) {
        newSearchParams.set(key, value.toString())
      } else {
        newSearchParams.delete(key)
      }
    })
    setSearchParams(newSearchParams)
  }

  return {
    params: {
      locationId,
      locationName: location,
      activityIds,
      activities,
      lat: lat ? Number(lat) : undefined,
      lng: lng ? Number(lng) : undefined,
      radius: radius ? parseInt(radius, 10) : undefined,
      polygons,
      fromView,
    },
    skipCall,
    updateSearchParam,
  }
}
