import { useEffect, useState } from 'react'

import { getMyCurrency } from 'features/common/helpers/getMyCurrency'
import { Currency } from 'features/common/types'
import { useGetCurrenciesQuery } from 'store/booking/api'

export interface IUseCurrency {
  isLoading: boolean
  currency: Currency | undefined
  currencies: Currency[]
  setCurrency: (currency: Currency) => void
}

export const useCurrency = (): IUseCurrency => {
  const [currency, setCurrency] = useState<Currency | undefined>(undefined)

  const { data: currencies, isLoading } = useGetCurrenciesQuery()

  useEffect(() => {
    getMyCurrency().then(c => setCurrency(c))
  }, [])

  return {
    isLoading,
    currency,
    currencies: currencies || [],
    setCurrency,
  }
}
