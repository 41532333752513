import { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'

import LayoutProvider from 'contexts/LayoutProvider'
import SearchProvider from 'contexts/SearchProvider'
import Layout from 'features/common/components/Layout'
import Loading from 'features/common/components/Loading'
import { useVh } from 'features/common/hooks/useVh'

import './index.css'

const SearchPage = lazy(() => import('pages/SearchPage'))
const FaqPage = lazy(() => import('pages/FaqPage'))
const NotFound = lazy(() => import('pages/NotFound'))
const PrivacyPolicyPage = lazy(() => import('pages/PrivacyPolicyPage'))
const TermsConditionsPage = lazy(() => import('pages/TermsConditionsPage'))
const HomePage = lazy(() => import('pages/HomePage'))
const ProductDetailPage = lazy(() => import('pages/ProductDetailPage'))
const VendorPage = lazy(() => import('pages/VendorPage'))

const App = () => {
  useVh()

  return (
    <BrowserRouter>
      <LayoutProvider>
        <SearchProvider>
          <Suspense fallback={<Loading label="Loading..." />}>
            <Routes>
              <Route element={<Layout />}>
                <Route path="/" element={<HomePage />} />
                <Route path="/search" element={<SearchPage />} />
                <Route path="/products/:inventoryGroupId" element={<ProductDetailPage />} />
                <Route path="/vendors/:vendorId" element={<VendorPage />} />
                <Route path="/faq" element={<FaqPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="/terms-conditions" element={<TermsConditionsPage />} />
              </Route>
              <Route path="/404" element={<NotFound />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Suspense>
        </SearchProvider>
      </LayoutProvider>
    </BrowserRouter>
  )
}

export default App
